import React from "react";

import loader from "../../assets/loader-star.svg";
import "./loader.css";

const Loader = () => {
  return (
    <div className="loader-star">
      <img src={loader} alt="loader" />
    </div>
  );
};

export default Loader;
